import axios from 'axios';
import { SETTINGS_ERROR } from '../utils/constantDefinition';

export const SET__SYSTEME_SETTINGS = 'SET__SYSTEME_SETTINGS';
export const SET_CONVENTION_SETTINGES = 'SET_CONVENTION_SETTINGES';
export const SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const SET_SYSTEM_ERRORS = 'SET_SYSTEM_ERRORS';

export const setSystemeSettings = (data) => ({ type: SET__SYSTEME_SETTINGS, data });
export const setConventionSettings = (data) => ({ type: SET_CONVENTION_SETTINGES, data });
export const setEmailSettings = (data) => ({ type: SET_EMAIL_SETTINGS, data });
export const toggleEditMode = (value) => ({ type: TOGGLE_EDIT_MODE, value });
export const setSystemErrors = (value) => ({ type: SET_SYSTEM_ERRORS, value })

export const getSystemeSettings = () => (dispatch) => {
    axios.get(`/back-tenant/api/v1/settings`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setSystemeSettings(response.data))
            }

        }).catch(function (error) {
            console.error(error)
        })
}

export const getConventionSettings = (level, status, sort) => (dispatch) => {
    axios.get(`/back-tenant/api/v1/convention-families/all?securityLevel=${level}&active=${status}${sort.map(element => (`&sort=${element.type},${element.direction}`)).join('')}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setConventionSettings(response.data))
            }

        }).catch(function (error) {
            console.error(error)
        })
}

export const getEmailSettings = () => (dispatch) => {
    axios.get(`/back-tenant/api/v1/tempalte-mail`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setEmailSettings(response.data))
            }

        }).catch(function (error) {
            console.error(error)
        })
}

export const UpdateSystemeSettings = (payload) => (dispatch) => {

    axios.put("/back-tenant/api/v1/settings",
        payload,
        {
        }).then((response) => {
            if (response.status === 200) {

                dispatch(getSystemeSettings())
                dispatch(toggleEditMode(false))
                dispatch(setSystemErrors())
            }
        }).catch(function (error) {
            dispatch(setSystemErrors(SETTINGS_ERROR))
        })
}

export const UpdateConventionSettings = (payload) => (dispatch) => {

    axios.put("/back-tenant/api/v1/convention-families",
        payload,
        {
        }).then((response) => {
            if (response.status === 200) {

                dispatch(getConventionSettings('','',[]))
                dispatch(toggleEditMode(false))
            }
        }).catch(function (error) {
            console.error(error)
        })
}

export const UpdateEmailSettings = (payload) => (dispatch) => {

    axios.put("/back-tenant/api/v1/template-mail",
        payload,
        {
        }).then((response) => {
            if (response.status === 200) {

                dispatch(getEmailSettings())
                dispatch(toggleEditMode(false))
            }
        }).catch(function (error) {
            console.error(error)
        })
}