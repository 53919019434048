import axios from 'axios';

export const SET_OPEN_ACCOUNTS_LIST = 'SET_OPEN_ACCOUNTS_LIST';
export const SET_KPI_STATISTICS = 'SET_KPI_STATISTICS';
export const SET_DASHBOARD_STATISTICS = 'SET_DASHBOARD_STATISTICS';

export const setOpenedAccounts = (data) => ({ type: SET_OPEN_ACCOUNTS_LIST, data });
export const setKpiStatistics = (data) => ({ type: SET_KPI_STATISTICS, data })
export const setDashboardStatistics = (data) => ({ type: SET_DASHBOARD_STATISTICS, data })

export const getOpenedAccounts = (dateFrom, dateTo) => (dispatch) => {
    axios.get(`/back-tenant/api/v1/statistics/opened-account?dateFrom=${dateFrom}&dateTo=${dateTo}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setOpenedAccounts(response.data))
            }

        }).catch(function (error) {

        })
}

export const getKpiStatistics = (dateFrom, dateTo, kpiFilter) => (dispatch) => {
    axios.get(`/back-tenant/api/v1/statistics/failed-login?dateFrom=${dateFrom}&dateTo=${dateTo}&kpiType=${kpiFilter}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setKpiStatistics(response.data))
            }

        }).catch(function (error) {

        })
}

export const getDashboardStatistics = () => (dispatch) => {
    axios.get(`/back-tenant/api/v1/statistics/stat-and-kpi`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setDashboardStatistics(response.data))
            }

        }).catch(function (error) {

        })
}