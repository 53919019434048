import Keycloak from "keycloak-js";
const keycloakUrl = "REACT_APP_KEYCLOAK_URL_PLACEHOLDER";
const keycloak = new Keycloak({
    url: keycloakUrl, 
    realm: 'hia-realm', 
    clientId: 'front-tenant',
    onLoad: 'login-required'
});

export default keycloak;
